import {
  useMemo,
  useState,
  Fragment,
  FC,
  useRef,
  useEffect,
  useLayoutEffect,
} from "react";
import { ShareIcon } from "@heroicons/react/outline";
import cx from "classnames";
import Link from "next/link";
import Image from "next/image";
import { clientParticlesConfig } from "constants/particles";
import { useRouter } from "next/router";
import Head from "next/head";
import Particles from "react-particles-js";
import { useQuery } from "@tanstack/react-query";
import { api } from "utils/api";
import {
  getFullName,
  toast,
  convertRating,
  getDecodedLocation,
  trackPageView,
  cloudfront,
  gradients,
} from "utils";
import {
  Expert,
  OfferItem,
  TipItem,
  TutorialItem,
  CommunityItem,
  EventItem,
} from "types/user";
import {
  checkIsDocument,
  checkIsImage,
  checkIsVideo,
  useExpertBlobsFields,
} from "helpers";
import useWindowDimensions from "hooks/useWindowDimensions";
import useReview from "hooks/useReview";
import useFollowers from "hooks/useFollowers";
import { TABLET_VIEW } from "constants/dimensions";
import Loader from "components/Loader/Loader";
import ClientNav from "../../components/ClientNav/ClientNav";
import ExpertPortfolio from "components/ExpertPortfolio/ExpertPortfolio";
import ExpertPortfolioContent from "components/ExpertPortfolioContent/ExpertPortfolioContent";
import ExpertReviewList from "components/ExpertReviewList/ExpertReviewList";
import ExpertModal from "components/ExpertModal/ExpertModal";
import ExpertMainInfo from "components/ExpertMainInfo/ExpertMainInfo";
import ExpertOfferList from "components/ExpertOfferList/ExpertOfferList";
import Script from "next/script";
import {
  UserCircleIcon,
  StarIcon,
  CollectionIcon,
  TagIcon,
  ThumbUpIcon,
  FireIcon,
  TicketIcon,
  DownloadIcon,
} from "@heroicons/react/outline";
// import ChatIcon from "components/Icons/ChatIcon/ChatIcon";
import "i18n";
import { useTranslation } from "react-i18next";
import { useSwipeable } from "react-swipeable";

const SwipeableTabs = ({
  tabsLinks,
  isMyProfile,
  visibleTabs,
  location,
  tutorialsRef,
  sessionsRef,
  communitiesRef,
  eventsRef,
  tipsRef,
  hasWindow,
  t,
  calculateCountsMobile,
  setActiveTabIndex,
  activeTabIndexRef,
  activeTabIndex,
}) => {
  const counts = calculateCountsMobile();

  useEffect(() => {
    const initialIndex = getInitialActiveTabIndex(
      tabsLinks,
      isMyProfile,
      visibleTabs,
      window.location.hash
    );
    if (initialIndex !== -1) {
      setActiveTabIndex(initialIndex);
      activeTabIndexRef.current = initialIndex;
    }
  }, [tabsLinks, isMyProfile, visibleTabs, window.location.hash]);

  const shouldShowIndicators = tabsLinks.length > 1;

  const indicators = shouldShowIndicators ? (
    <div className="flex justify-center items-center md:hidden">
      {tabsLinks
        .filter((tab) => visibleTabs[tab])
        .map((tab, index) => {
          let sizeClass = "";
          const distance = Math.abs(index - activeTabIndex);
          if (distance === 0) {
            sizeClass = "w-3 h-3";
          } else if (distance === 1) {
            sizeClass = "w-2.5 h-2.5";
          } else {
            sizeClass = "w-1.5 h-1.5";
          }

          const colorClass = cx({
            "bg-landing-red": index === activeTabIndex,
            "bg-gray-300": index !== activeTabIndex,
          });

          return (
            <div
              key={tab}
              className={`${sizeClass} ${colorClass} rounded-full mx-1`}
            />
          );
        })}
    </div>
  ) : null;

  const icons = {
    tips: <ThumbUpIcon className="w-5 h-5 shrink-0 text-landing-red" />,
    communities: <FireIcon className="w-5 h-5 shrink-0 text-landing-red" />,
    products: <DownloadIcon className="w-5 h-5 shrink-0 text-landing-red" />,
    appointments: (
      <span className="icon-[tabler--calendar-clock] text-landing-red w-5 h-5 shrink-0" />
    ),
    events: <TicketIcon className="w-5 h-5 shrink-0 text-landing-red" />,
  };

  const n = tabsLinks.filter(
    (tab) =>
      visibleTabs[tab] && hasWindow && !window.location.hash.includes(`${tab}`)
  ).length;
  const totalInactiveWidth = n * 54;
  const activeWidth = `calc(100% - ${totalInactiveWidth}px)`;

  const links = tabsLinks
    .filter((tab) => visibleTabs[tab])
    .map((tab) => {
      const ref =
        tab === "products" || tab === "tutorials"
          ? tutorialsRef
          : tab === "appointments"
          ? sessionsRef
          : tab === "communities"
          ? communitiesRef
          : tab === "events"
          ? eventsRef
          : tipsRef;
      /* eslint-disable */
      return (
        <div
          ref={ref}
          style={{
            width:
              hasWindow && window.location.hash.includes(`${tab}`)
                ? activeWidth
                : "48px",
          }}
        >
          <Link href={`#${tab}`} passHref>
            <div
              className={cx(
                " py-[9px] rounded-[18px] bg-slate-100 flex justify-center items-center",
                {
                  "!bg-red-100 px-2 rounded-[20px] !text-landing-red flex gap-2 justify-center items-center":
                    hasWindow && window.location.hash.includes(`${tab}`),
                  "px-[13px]":
                    hasWindow && !window.location.hash.includes(`${tab}`),

                  hidden: !visibleTabs[tab],
                }
              )}
            >
              {!location.hash.includes(`${tab}`) && (
                <span className="text-sm font-medium mr-1 text-landing-red">
                  {counts[tab]}
                </span>
              )}
              {icons[tab]}
              <p
                className={cx("truncate text-xs font-medium", {
                  hidden: hasWindow && !window.location.hash.includes(`${tab}`),
                })}
              >
                {t(tab)}
              </p>
            </div>
          </Link>
        </div>
      );
      /* eslint-disable */
    });

  return (
    <div>
      {" "}
      <div className="p-5 md:hidden flex justify-between gap-2.5">{links}</div>
      {indicators}
    </div>
  );
};

function getInitialActiveTabIndex(
  tabsLinks,
  isMyProfile,
  visibleTabs,
  locationHash
) {
  return tabsLinks
    .filter((tab) => (isMyProfile ? true : visibleTabs[tab]))
    .findIndex((tab) => locationHash.includes(`${tab}`));
}

export const ExpertPage: FC<{
  expert: Expert;
  acceptLanguageHeader: string;
  publicId?: any;
  tipsNext?;
  communitiesNext?;
  eventsNext?;
  tutorialsNext?;
  offersNext?;
}> = ({
  expert,
  acceptLanguageHeader,
  publicId,
  tipsNext,
  communitiesNext,
  eventsNext,
  tutorialsNext,
  offersNext,
}) => {
  const router = useRouter();
  const { width, height } = useWindowDimensions();
  const publicIdForMetaTags = publicId || (router.query.publicId as string);
  const sessionsRef = useRef(null);
  const tutorialsRef = useRef(null);
  const communitiesRef = useRef(null);
  const eventsRef = useRef(null);
  const tipsRef = useRef(null);
  const reviewListRef = useRef(null);
  const [offers, setOffers] = useState<OfferItem[]>(
    offersNext?.length > 0
      ? offersNext?.filter(
          ({ isEnabled, sharing }) => isEnabled && sharing !== "link"
        )
      : []
  );
  const [tutorials, setTutorials] = useState<TutorialItem[]>(
    tutorialsNext?.length > 0
      ? tutorialsNext?.filter(({ isEnabled }) => isEnabled)
      : []
  );
  const [communities, setCommunities] = useState<CommunityItem[]>(
    communitiesNext?.length > 0
      ? communitiesNext?.filter(({ isEnabled }) => isEnabled)
      : []
  );
  const [tips, setTips] = useState<TipItem[]>(
    tipsNext?.length > 0 ? tipsNext?.filter(({ isEnabled }) => isEnabled) : []
  );
  const [events, setEvents] = useState<EventItem[]>(
    eventsNext?.length > 0
      ? eventsNext?.filter(({ isEnabled }) => isEnabled)
      : []
  );
  const [expertId, setExpertId] = useState("");
  const hasWindow = typeof window !== "undefined";
  const { t } = useTranslation();
  const imgRef = useRef<HTMLImageElement>(null);
  const [hash, setHash] = useState("");
  const [sortedTabs, setSortedTabs] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const activeTabIndexRef = useRef(activeTabIndex);
  const [stylesExpert, setStylesExpert] = useState("black");

  useEffect(() => {
    if (expert?.styles) {
      setStylesExpert(
        gradients.find((g) => g.id === Number(expert?.styles?.gradientId))
          ?.gradient ||
          expert?.styles?.solidColor ||
          "black"
      );
    }
  }, [expert?.styles]);

  const handleSwipe = (e) => {
    let newIndex;
    if (e.dir === "Left") {
      newIndex = Math.min(activeTabIndexRef.current + 1, tabsLinks.length - 1);
    } else if (e.dir === "Right") {
      newIndex = Math.max(activeTabIndexRef.current - 1, 0);
    } else {
      return;
    }

    if (newIndex !== activeTabIndexRef.current) {
      const nextTab = tabsLinks[newIndex];
      window.location.hash = `#${nextTab}`;
      setActiveTabIndex(newIndex);
      activeTabIndexRef.current = newIndex;
    }
  };

  const handlers = useSwipeable({
    onSwiped: handleSwipe,
    trackMouse: true,
  });

  type VisibleTabs = {
    appointments?: boolean;
    products?: boolean;
    communities?: boolean;
    events?: boolean;
    tips?: boolean;
    portfolio?: boolean;
    reviews?: boolean;
  };

  const [visibleTabs, setVisibleTabs] = useState<VisibleTabs>({
    appointments: true,
    products: true,
    communities: true,
    events: true,
    tips: true,
    portfolio: true,
    reviews: true,
  });

  useEffect(() => {
    if (expert?.metapixelid) {
      trackPageView(expert?.metapixelid, "", "Offer List");
    }
  }, [expert?.metapixelid]);

  const { data: blobs } = useQuery(
    ["blobs", publicId],
    () => api.get(`/blobs/v1/blobs?expert_id=${expert?.id || expertId}`),
    {
      enabled: !!expert?.id,
    }
  );
  const [isExpertModalOpen, setIsExpertModalOpen] = useState(false);
  const [expertModalType, setExpertModalType] = useState<"profile" | "none">(
    "none"
  );
  const noExpert = !expert?.id || !expert?.isActive;
  const {
    firstName,
    lastName,
    avatar,
    tags,
    about,
    id,
    isSoldEnabled,
    styles,
  } = expert || {};

  const fullName = getFullName(firstName, lastName, publicIdForMetaTags);
  const [showPortfolio, setShowPortfolio] = useState(false);
  const [selectedItem, setSelectedItem] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);

  const galleryValues = useMemo(() => [...(blobs || [])], [blobs]);
  const { portfolioValue, headerImages, headerVideos } =
    useExpertBlobsFields(galleryValues);

  const headerImageFile = headerImages[0];
  const headerVideoFile = headerVideos[0];
  const headerImageLink =
    headerImages?.length > 0 && cloudfront + headerImageFile?.inStoreId;
  const headerVideoLink =
    headerVideos?.length > 0 && cloudfront + headerVideoFile?.inStoreId;

  const [size, setSize] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    const img = imgRef.current;
    if (img && img.complete) {
      setSize({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    } else if (img) {
      img.onload = () => {
        setSize({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      };
    }
  }, [headerImageLink]);

  useEffect(() => {
    if (parseFloat(headerImageFile?.flags?.y) && imgRef.current) {
      const { style } = imgRef.current;
      style.transform = `translate3d(0, ${
        parseFloat(headerImageFile.flags.y) - 8
      }%, 0)`;
    }
  }, [headerImageFile?.flags?.y]);

  const portfolioMediaList = portfolioValue.filter((file: any) => {
    const fileType = file.inStoreId.split(".").slice(-1)[0];

    return checkIsImage(fileType) || checkIsVideo(fileType);
  });
  const portfolioOtherList = portfolioValue.filter((file: any) => {
    const fileType = file.inStoreId.split(".").slice(-1)[0];

    return checkIsDocument(fileType);
  });

  const portfolioFiles = portfolioMediaList
    .filter((file: any) => {
      const fileType = file.inStoreId.split(".").slice(-1)[0];

      return checkIsImage(fileType);
    })
    ?.map((file: any) => ({
      original: cloudfront + file.inStoreId,
      name: file.filename,
      height,
    }));

  const handleAuth = () =>
    router.push({
      pathname: "/sign-in",
      query: {
        returnTo: `/${publicId}`,
        expertId: `${id}`,
        chat: "true",
      },
    });

  const handleSubscribeAuth = () =>
    router.push({
      pathname: "/sign-in",
      query: {
        returnTo: `/${publicId}`,
        expertId: `${id}`,
        subscribe: "true",
      },
    });

  const { asPath, basePath } = router;
  const url = `${basePath}${asPath}`;
  const shareData = {
    title: "Waylight",
    text: "Waylight",
    url,
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        handleClipboard();
      }
    } catch (error) {
      console.log("Error sharing", error);
    }
  };

  const handleClipboard = () => {
    try {
      const path = window.location.hash
        ? `${window.location.origin}/${publicId}${window.location.hash}`
        : `${window.location.origin}/${publicId}`;
      window.navigator.clipboard.writeText(path);
      toast(t("toastProfileCopied"), { type: "success" });
    } catch (e: any) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    if (window?.location?.href?.includes("%23")) {
      const newLocation = getDecodedLocation();
      router.replace(newLocation);
    }
  }, []);

  useEffect(() => {
    const newUrl = getDecodedLocation();
    if (newUrl.hash === "#tutorials") {
      router.replace({
        pathname: newUrl.pathname,
        hash: "#products",
      });
    }
  }, []);

  useEffect(() => {
    if (
      window.location.hash.includes("fbclid") &&
      window.location.hash.includes("leavereview")
    ) {
      const cleanHash = window.location.hash.split("?")[0];
      window.location.hash = cleanHash === "#leavereview" && "reviews";

      return;
    }
  }, []);

  useEffect(() => {
    if (window?.location?.href?.includes("%23")) {
      const newLocation = getDecodedLocation();
      router.replace(newLocation);
    } else {
      const availableTabs = noExpert
        ? []
        : Object.keys(expert?.tabs)
            .filter((tabName) => expert?.tabs[tabName] !== false)
            .map((tabName) => (tabName === "tutorials" ? "products" : tabName));

      if (availableTabs.length === 0 && !noExpert) {
        if (expert?.tabs["portfolio"] !== false) {
          availableTabs.push("portfolio");
        }
        if (expert?.tabs["reviews"] !== false) {
          availableTabs.push("reviews");
        }
      }
      if (availableTabs.length > 0) {
        const currentTab = window.location.hash.replace("#", "");
        if (window.location.hash.includes("fbclid")) {
          const cleanUrl = window.location.hash.split("?")[0];
          router.replace(`${cleanUrl}`);
          return;
        }

        if (currentTab === "sessions") {
          router.replace(`#appointments`);
          return;
        }

        if (!availableTabs.includes(currentTab)) {
          let firstAvailableTab = availableTabs[0];
          if (currentTab.includes("tutorials")) {
            firstAvailableTab = "product";
          }
          if (currentTab === "portfolio" || currentTab === "reviews") {
            firstAvailableTab = currentTab;
          }

          setTimeout(() => {
            router.replace(`#${firstAvailableTab}`);
          }, 0);
        }
      }
    }
  }, [expert?.tabs]);

  const title: string = tags?.length ? `${fullName} - ${tags[0]}` : fullName;

  const { review, reviewLoading } = useReview(id);

  const reviews = review?.expertReviews;
  const { followers } = useFollowers(id);

  const rating = convertRating(review?.rating);
  const isMobile = width <= TABLET_VIEW;

  useEffect(() => {
    if (expert?.tabs) {
      const expertTabsOrder = Object.keys(expert.tabs);

      const newTabs = {
        ...Object.fromEntries(
          expertTabsOrder.map((key) => [
            key === "tutorials" ? "products" : key,
            expert.tabs[key],
          ])
        ),
        portfolio: true,
        reviews: true,
      };
      setVisibleTabs(newTabs);
    }
  }, [expert.tabs]);

  function calculateCountsMobile() {
    return {
      appointments: visibleTabs.appointments ? offers.length : 0,
      products: visibleTabs.products ? tutorials.length : 0,
      tips: visibleTabs.tips ? tips.length : 0,
      events: visibleTabs.events ? events.length : 0,
      communities: visibleTabs.communities ? communities.length : 0,
    };
  }

  function calculateCount() {
    let tempCount = 0;

    if (isMobile) {
      if (visibleTabs.appointments) {
        tempCount += offers?.length;
      }
      if (visibleTabs.products) {
        tempCount += tutorials?.length;
      }
      if (visibleTabs.tips) {
        tempCount += tips?.length;
      }
      if (visibleTabs.events) {
        tempCount += events?.length;
      }
      if (visibleTabs.communities) {
        tempCount += communities?.length;
      }
    } else {
      if (visibleTabs.appointments) {
        tempCount = offers?.length;
      }
    }

    return tempCount;
  }

  let acTab = Object.keys(visibleTabs).find((key) => visibleTabs[key] === true);

  const count = calculateCount();

  const isOfferMobileActive =
    hasWindow &&
    window.location.hash.includes(
      ["#events", "#tips", "#products", "#appointments", "#communities"].find(
        (tab) => location.hash.includes(tab)
      )
    );

  const closeModal = () => {
    setIsExpertModalOpen(false);
  };

  const onOpenAllInfo = () => {
    setIsExpertModalOpen(true);
    setExpertModalType("profile");
  };

  const tabs = useMemo(
    () => [
      {
        name: isMobile ? "offers" : "appointments",
        href:
          isMobile && hasWindow && acTab !== "portfolio"
            ? window.location.hash !== "#reviews" &&
              window.location.hash !== "#portfolio"
              ? window.location.hash
              : `#${acTab}`
            : "#appointments",
        current: true,
        icon: isMobile && (
          <p
            className={cx(
              "w-14 h-14 flex justify-center items-center mb-2 border border-slate-200 rounded-[15.44px]",
              {
                "bg-slate-100": isOfferMobileActive,
              }
            )}
          >
            <TagIcon
              className={`w-6 h-6 shrink-0 stroke-2.5 ${
                isOfferMobileActive ? "text-slate-500" : "text-slate-900"
              }`}
            />
          </p>
        ),
        count,
        isHidden:
          (!visibleTabs.appointments && !isMobile) ||
          (!visibleTabs.appointments &&
            !visibleTabs.products &&
            !visibleTabs.tips &&
            !visibleTabs.events &&
            !visibleTabs.communities),
      },

      {
        name: "products",
        count: tutorials?.length,
        href: "#products",
        current: true,
        isHidden: isMobile || !visibleTabs.products,
      },
      {
        name: "communities",
        count: communities?.length,
        href: "#communities",
        current: true,
        isHidden: isMobile || !visibleTabs.communities,
      },
      {
        name: "events",
        count: events?.length,
        href: "#events",
        current: true,
        isHidden: isMobile || !visibleTabs.events,
      },
      {
        name: "tips",
        count: tips?.length,
        href: "#tips",
        current: true,
        isHidden: isMobile || !visibleTabs.tips,
      },
      {
        name: "portfolio",
        count: portfolioValue ? portfolioValue.length : 0,
        href: "#portfolio",
        icon: isMobile && (
          <p
            className={cx(
              "w-14 h-14 flex justify-center items-center mb-2 border border-slate-200 rounded-[15.44px]",
              {
                "bg-slate-100":
                  hasWindow && window.location.hash.includes("portfolio"),
              }
            )}
          >
            <CollectionIcon
              className={`w-6 h-6 shrink-0 stroke-2.5 ${
                hasWindow && window.location.hash.includes("portfolio")
                  ? "text-slate-500"
                  : "text-slate-900"
              }`}
            />
          </p>
        ),
        current: true,
        isHidden: false,
      },
      {
        name: "reviews",
        count: reviews ? reviews.length : 0,
        href: "#reviews",
        icon: isMobile && (
          <p
            className={cx(
              "w-14 h-14 flex justify-center items-center mb-2 border border-slate-200 rounded-[15.44px]",
              {
                "bg-slate-100":
                  hasWindow && window.location.hash.includes("reviews"),
              }
            )}
          >
            <StarIcon
              className={`w-6 h-6 shrink-0 stroke-2.5 ${
                hasWindow && window.location.hash.includes("reviews")
                  ? "text-slate-500"
                  : "text-slate-900"
              }`}
            />
          </p>
        ),
        ref: reviewListRef,
        isHidden: false,
      },
      // {
      //   name: "buttonOpenChat",
      //   icon: isMobile && (
      //     <button
      //       disabled={!expert?.freeChat}
      //       onClick={handleAuth}
      //       className={cx(
      //         "w-14 h-14 flex disabled:opacity-50 disabled:pointer-events-none justify-center items-center mb-2 border border-slate-200 rounded-[15.44px]"
      //       )}
      //     >
      //       <ChatIcon className="w-6 h-6 shrink-0 stroke-2.5 stroke-slate-900 text-slate-900" />
      //     </button>
      //   ),
      //   current: false,
      //   isHidden: !isMobile || !expert?.freeChat,
      // },
      {
        name: "aboutMe",
        icon: isMobile && (
          <p
            onClick={onOpenAllInfo}
            className={cx(
              "w-14 h-14 flex justify-center items-center mb-2 border border-slate-200 rounded-[15.44px]"
            )}
          >
            <UserCircleIcon
              className={`w-6 h-6 shrink-0 stroke-2.5 text-slate-900`}
            />
          </p>
        ),
        current: false,
        isHidden: !isMobile,
      },
    ],
    [
      isMobile,
      hasWindow,
      acTab,
      visibleTabs,
      tutorials?.length,
      communities?.length,
      events?.length,
      tips?.length,
      offers?.length,
      portfolioValue ? portfolioValue.length : 0,
      reviews ? reviews.length : 0,
      expert?.freeChat,
      hash,
    ]
  );

  useEffect(() => {
    let newSortedTabs = noExpert
      ? tabs
      : Object.keys(expert?.tabs).reduce((acc, key) => {
          let actualKey = key === "tutorials" ? "products" : key;
          let found = tabs.find((tab) => tab.name === actualKey);
          if (found) {
            acc.push(found);
          }
          return acc;
        }, []);

    let offersTab = tabs.find((tab) => tab.name === "offers");
    if (offersTab) {
      newSortedTabs.push(offersTab);
    }
    let buttonOpenChat = tabs.find((tab) => tab.name === "buttonOpenChat");
    if (buttonOpenChat) {
      newSortedTabs.push(buttonOpenChat);
    }
    let portfolioTab = tabs.find((tab) => tab.name === "portfolio");
    if (portfolioTab) {
      newSortedTabs.push(portfolioTab);
    }

    let reviewsTab = tabs.find((tab) => tab.name === "reviews");
    if (reviewsTab) {
      newSortedTabs.push(reviewsTab);
    }

    let aboutMe = tabs.find((tab) => tab.name === "aboutMe");
    if (aboutMe) {
      newSortedTabs.push(aboutMe);
    }

    setSortedTabs(newSortedTabs);
  }, [tabs]);

  const isMobileDevice =
    hasWindow && /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const expertMainInfoProps = {
    ...expert,
    fullName,
    headerVideoFile,
    headerVideoLink,
    isPlaying,
    setIsPlaying,
    handleNewReview: handleAuth,
    handleProfileButton: isMobileDevice ? handleShare : handleClipboard,
    handleFollow: handleSubscribeAuth,
    onOpenAllInfo,
    rating,
    reviews,
    followers,
    isMobile,
  };

  const listType = portfolioMediaList.length
    ? "media"
    : portfolioOtherList.length
    ? "other"
    : "media";

  const content = about ? `${about}` : t("connectWaylight");

  useEffect(() => {
    setHash(window.location.hash);

    const handleHashChange = () => {
      setHash(window.location.hash);
    };

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [router.asPath]);

  const isPortfolio = hash === "#portfolio";
  const isSessions = hash === "#appointments";
  const isTutorials = hash === "#products";
  const isCommunities = hash === "#communities";
  const isTips = hash === "#tips";
  const isEvents = hash === "#events";

  const gtmid = expert?.gtmid;
  const hotjarid = expert?.hotjarid;
  const metapixelid = expert?.metapixelid;

  function findFirstVisibleTab(sortedTabs) {
    if (sortedTabs.length > 0) {
      return sortedTabs.find((tab) => !tab.isHidden);
    }
  }
  const firstVisibleTab = findFirstVisibleTab(sortedTabs);
  const activeTab =
    tabs.find((tab) => router.asPath.includes(tab.href))?.name ||
    firstVisibleTab?.name;

  const available = {};

  for (const key in expert?.tabs) {
    if (expert?.tabs[key] === true) {
      available[key] = true;
    }
  }

  let tabsLinks = noExpert
    ? []
    : [
        ...Object.keys(available).map((key) =>
          key === "tutorials" ? "products" : key
        ),
      ];

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <>
      <Head>
        {gtmid ? (
          <Script
            id="profilegtm"
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                 new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                 j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                 })(window,document,'script','dataLayer','${gtmid}');`,
            }}
          />
        ) : null}

        {hotjarid ? (
          <Script
            id="profilehotjar"
            dangerouslySetInnerHTML={{
              __html: `(function(h,o,t,j,a,r){
                           h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                           h._hjSettings={hjid:${hotjarid},hjsv:6};
                           a=o.getElementsByTagName('head')[0];
                           r=o.createElement('script');r.async=1;
                           r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                           a.appendChild(r);
                       })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
            }}
          />
        ) : null}

        {metapixelid ? (
          <Script
            id="profilemeta"
            dangerouslySetInnerHTML={{
              __html: `!function(f,b,e,v,n,t,s)
                                       {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                                       n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                                       if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                                       n.queue=[];t=b.createElement(e);t.async=!0;
                                       t.src=v;s=b.getElementsByTagName(e)[0];
                                       s.parentNode.insertBefore(t,s)}(window, document,'script',
                                       'https://connect.facebook.net/en_US/fbevents.js');
                                       fbq('init', '${metapixelid}');
                                       `,
            }}
          />
        ) : null}

        <title>{title}</title>
        <meta name="description" content={content} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={content} />
        <meta property="og:image" content={avatar} />
        <meta
          property="og:url"
          content={`https://waylight.me/${publicIdForMetaTags}`} // TODO: change URL
        />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={content} />
        <meta property="twitter:image" content={avatar} />
        <meta property="twitter:card" content="summary" />
      </Head>

      {gtmid ? (
        <Script
          id="profilegtm"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                 new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                 j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                 })(window,document,'script','dataLayer','${gtmid}');`,
          }}
        />
      ) : null}

      {hotjarid ? (
        <Script
          id="profilehotjar"
          dangerouslySetInnerHTML={{
            __html: `(function(h,o,t,j,a,r){
                           h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                           h._hjSettings={hjid:${hotjarid},hjsv:6};
                           a=o.getElementsByTagName('head')[0];
                           r=o.createElement('script');r.async=1;
                           r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                           a.appendChild(r);
                       })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
          }}
        />
      ) : null}

      {metapixelid ? (
        <Script
          id="profilemetanew"
          dangerouslySetInnerHTML={{
            __html: `!function(f,b,e,v,n,t,s)
                                       {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                                       n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                                       if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                                       n.queue=[];t=b.createElement(e);t.async=!0;
                                       t.src=v;s=b.getElementsByTagName(e)[0];
                                       s.parentNode.insertBefore(t,s)}(window, document,'script',
                                       'https://connect.facebook.net/en_US/fbevents.js');
                                       fbq('init', '${metapixelid}');
                           
                                       fbq('track', 'PageView');`,
          }}
        />
      ) : null}

      <ClientNav
        hasButtons={false}
        headerColor={true}
        acceptLanguageHeader={acceptLanguageHeader}
      />
      <div>
        {showPortfolio ? (
          <ExpertPortfolio
            files={portfolioFiles}
            onClose={() => {
              setShowPortfolio(false);
              setSelectedItem(1);
            }}
            show={showPortfolio}
            selectedItem={selectedItem}
            height={height}
            width={width}
          />
        ) : null}
        {noExpert ? (
          <div className="flex items-center justify-center w-full h-screen -mt-16 pt-16 text-center">
            <div className="flex flex-col items-center max-w-sm">
              <Image
                src="/img/empty-expert.svg"
                width={128}
                height={128}
                alt="no expert"
              />
              <h3 className="font-bold mt-20 text-lg">
                {t("emptyExpertTitle")}
              </h3>
              <p className="text-sm font-medium opacity-50 mt-4">
                {t("emptyExpertText")}
              </p>
            </div>
          </div>
        ) : (
          <>
            <div className="hidden md:block">
              {headerImageLink ? (
                <div className="h-[150px] md:h-[200px] overflow-hidden">
                  <img
                    ref={imgRef}
                    className={`w-full h-auto object-cover transition-opacity duration-300 ${
                      size?.height ? "opacity-100" : "opacity-0"
                    } ${
                      size?.height && size.height <= 200
                        ? "md:h-[200px]"
                        : "md:min-h-[200px]"
                    }`}
                    src={headerImageLink}
                    alt=""
                  />
                </div>
              ) : (
                <div className="relative">
                  {styles?.animation ? (
                    <Particles
                      params={clientParticlesConfig}
                      className="absolute inset-0 pointer-events-none h-[150px] md:h-[200px] z-10"
                    />
                  ) : null}

                  <div
                    className={cx(
                      "w-full h-[150px] md:h-[200px] relative overflow-hidden flex items-center justify-center"
                    )}
                    style={{
                      background: stylesExpert,
                    }}
                  />
                </div>
              )}
            </div>
            <div className="mx-auto md:pt-4 pb-4 md:px-4 xl:px-8 max-w-screen-xl">
              <div className="rounded-lg md:rounded-none md:grid grid-cols-12 gap-x-6">
                <div className="col-span-4 bg-white md:bg-transparent rounded-t-lg">
                  {
                    <ExpertMainInfo
                      {...expertMainInfoProps}
                      viewType="public"
                    />
                  }
                </div>
                <div className="col-span-8 bg-white md:bg-transparent rounded-b-lg overflow-x-hidden">
                  <div className="md:px-4 py-3 md:shadow md:rounded-lg text-sm bg-white rounded-b-lg">
                    <div className="mb-5 md:mb-4 flex justify-between md:justify-between mx-5 md:mx-0 md:border-b border-gray-100">
                      <nav
                        className="-mb-px flex justify-between gap-3.5 xxs:gap-4 xs:gap-10 md:px-0 md:gap-0 md:justify-start w-full md:w-auto"
                        aria-label="Tabs"
                      >
                        {sortedTabs.map(
                          ({ name, href, count, ref, isHidden, icon }) => {
                            const isActiveTab = activeTab === name;

                            return isHidden ? null : (
                              <Fragment key={name}>
                                <div
                                  className="inline-flex md:w-auto cursor-pointer"
                                  ref={ref}
                                >
                                  {!href ? (
                                    <div
                                      className={cx(
                                        "inline-flex md:block text-center border-transparent lg:hover:text-gray-700 lg:hover:border-gray-300 whitespace-nowrap border-b-2 ",
                                        {
                                          "border-landing-red text-gray-900 font-semibold":
                                            isActiveTab,
                                        }
                                      )}
                                    >
                                      <div className="flex flex-col items-center md:block pb-2 md:px-3">
                                        {icon}
                                        <p
                                          className={cx({
                                            "text-center text-[11px] xs:text-xs text-slate-700":
                                              isMobile,
                                          })}
                                        >
                                          {t(name)}
                                        </p>
                                        <p className="text-gray-900 hidden md:block font-semibold mt-1">
                                          {count}
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    <Link href={href || "#"} passHref>
                                      <div
                                        className={cx(
                                          " flex flex-col items-center md:block text-center border-transparent lg:hover:text-gray-700 lg:hover:border-gray-300 whitespace-nowrap border-b-2 ",
                                          {
                                            "border-landing-red text-gray-900 font-semibold":
                                              isActiveTab,
                                            "md:text-gray-400": !isActiveTab,
                                          }
                                        )}
                                      >
                                        <div className="flex flex-col items-center md:block pb-2 md:px-3">
                                          {icon}
                                          <p
                                            className={cx({
                                              "text-center text-[11px] xs:text-xs text-slate-700":
                                                isMobile,
                                            })}
                                          >
                                            {t(name)}
                                          </p>
                                          <p className="text-gray-900 hidden md:block font-semibold mt-1">
                                            {count}
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                </div>
                                {!isHidden && (
                                  <div className="hidden md:block border-r border-gray-100 last:border-r-0" />
                                )}
                              </Fragment>
                            );
                          }
                        )}
                      </nav>
                      <div className="hidden md:flex gap-x-1 pb-3 pt-0.5">
                        <div className="px-3 py-2">
                          <ShareIcon
                            className="w-5 h-5 opacity-50 hover:opacity-100 cursor-pointer clipboard"
                            onClick={handleClipboard}
                          />
                        </div>
                      </div>
                    </div>

                    {isSessions ? (
                      <div {...handlers}>
                        <ExpertOfferList
                          offers={offers}
                          publicId={publicId}
                          isBusinessType={false}
                          type="public"
                          isSoldEnabled={isSoldEnabled}
                        >
                          <SwipeableTabs
                            tabsLinks={tabsLinks}
                            isMyProfile={false}
                            visibleTabs={visibleTabs}
                            location={window.location}
                            tutorialsRef={tutorialsRef}
                            sessionsRef={sessionsRef}
                            communitiesRef={communitiesRef}
                            eventsRef={eventsRef}
                            tipsRef={tipsRef}
                            hasWindow={hasWindow}
                            t={t}
                            calculateCountsMobile={calculateCountsMobile}
                            setActiveTabIndex={setActiveTabIndex}
                            activeTabIndexRef={activeTabIndexRef}
                            activeTabIndex={activeTabIndex}
                          />
                        </ExpertOfferList>
                      </div>
                    ) : null}

                    {isTutorials ? (
                      <div {...handlers}>
                        <ExpertOfferList
                          offers={tutorials}
                          publicId={publicId}
                          isBusinessType={false}
                          type="public"
                          offerType="product"
                          isSoldEnabled={isSoldEnabled}
                        >
                          <SwipeableTabs
                            tabsLinks={tabsLinks}
                            isMyProfile={false}
                            visibleTabs={visibleTabs}
                            location={location}
                            tutorialsRef={tutorialsRef}
                            sessionsRef={sessionsRef}
                            communitiesRef={communitiesRef}
                            eventsRef={eventsRef}
                            tipsRef={tipsRef}
                            hasWindow={hasWindow}
                            t={t}
                            calculateCountsMobile={calculateCountsMobile}
                            setActiveTabIndex={setActiveTabIndex}
                            activeTabIndexRef={activeTabIndexRef}
                            activeTabIndex={activeTabIndex}
                          />
                        </ExpertOfferList>
                      </div>
                    ) : null}
                    {isEvents ? (
                      <div {...handlers}>
                        <ExpertOfferList
                          offers={events}
                          publicId={publicId}
                          isBusinessType={false}
                          type="public"
                          offerType="event"
                          isSoldEnabled={isSoldEnabled}
                          timeZonePublic={timeZone}
                        >
                          <SwipeableTabs
                            tabsLinks={tabsLinks}
                            isMyProfile={false}
                            visibleTabs={visibleTabs}
                            location={location}
                            tutorialsRef={tutorialsRef}
                            sessionsRef={sessionsRef}
                            communitiesRef={communitiesRef}
                            eventsRef={eventsRef}
                            tipsRef={tipsRef}
                            hasWindow={hasWindow}
                            t={t}
                            calculateCountsMobile={calculateCountsMobile}
                            setActiveTabIndex={setActiveTabIndex}
                            activeTabIndexRef={activeTabIndexRef}
                            activeTabIndex={activeTabIndex}
                          />
                        </ExpertOfferList>
                      </div>
                    ) : null}
                    {isCommunities ? (
                      <div {...handlers}>
                        <ExpertOfferList
                          offers={communities}
                          publicId={publicId}
                          isBusinessType={false}
                          type="public"
                          offerType="community"
                          isSoldEnabled={isSoldEnabled}
                        >
                          <SwipeableTabs
                            tabsLinks={tabsLinks}
                            isMyProfile={false}
                            visibleTabs={visibleTabs}
                            location={location}
                            tutorialsRef={tutorialsRef}
                            sessionsRef={sessionsRef}
                            communitiesRef={communitiesRef}
                            eventsRef={eventsRef}
                            tipsRef={tipsRef}
                            hasWindow={hasWindow}
                            t={t}
                            calculateCountsMobile={calculateCountsMobile}
                            setActiveTabIndex={setActiveTabIndex}
                            activeTabIndexRef={activeTabIndexRef}
                            activeTabIndex={activeTabIndex}
                          />
                        </ExpertOfferList>
                      </div>
                    ) : null}

                    {isTips ? (
                      <div {...handlers}>
                        <ExpertOfferList
                          offers={tips}
                          publicId={publicId}
                          isBusinessType={false}
                          type="public"
                          offerType="tip"
                          isSoldEnabled={isSoldEnabled}
                        >
                          <SwipeableTabs
                            tabsLinks={tabsLinks}
                            isMyProfile={false}
                            visibleTabs={visibleTabs}
                            location={location}
                            tutorialsRef={tutorialsRef}
                            sessionsRef={sessionsRef}
                            communitiesRef={communitiesRef}
                            eventsRef={eventsRef}
                            tipsRef={tipsRef}
                            hasWindow={hasWindow}
                            t={t}
                            calculateCountsMobile={calculateCountsMobile}
                            setActiveTabIndex={setActiveTabIndex}
                            activeTabIndexRef={activeTabIndexRef}
                            activeTabIndex={activeTabIndex}
                          />
                        </ExpertOfferList>
                      </div>
                    ) : null}

                    {isPortfolio ? (
                      <ExpertPortfolioContent
                        portfolioMediaList={portfolioMediaList}
                        portfolioOtherList={portfolioOtherList}
                        setShowPortfolio={setShowPortfolio}
                        setSelectedItem={setSelectedItem}
                        listType={listType}
                        portfolioImgList={portfolioFiles}
                        isPresentationPlaying={isPlaying}
                        setIsPresentationPlaying={setIsPlaying}
                      />
                    ) : null}
                    <div className="px-3 md:px-0">
                      {hash === "#reviews" ? (
                        reviewLoading ? (
                          <div className="mt-10  mb-9 text-center">
                            <Loader fullScreen={false} />
                          </div>
                        ) : (
                          <ExpertReviewList
                            reviews={reviews}
                            isReviewLoading={reviewLoading}
                          />
                        )
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ExpertModal
              isModalOpen={isExpertModalOpen}
              onModalClose={closeModal}
              type={expertModalType}
            >
              <div className="bg-white rounded-lg mt-24 mb-20 pb-6 md:pb-0">
                <ExpertMainInfo
                  {...expertMainInfoProps}
                  type="modal"
                  viewType="public"
                />
              </div>
            </ExpertModal>
          </>
        )}
      </div>
    </>
  );
};
